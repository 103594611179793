import { useState } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import DetailedPin from "./DetailedPin";
import UpdatedHero from "./UpdatedHero";

export default function Locator() {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const googleMapsId = process.env.REACT_APP_GOOGLE_MAPS_ID;
  const position = { lat: 44.02923, lng: -92.487165 };
  const [zoom, setZoom] = useState(12);
  const [fullScreen, setFullScreen] = useState(false);

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom.detail.zoom);
  };

  const makeFullScreen = () => {
    setFullScreen(true);
  };
  const minimizeFullScreen = () => {
    setFullScreen(false);
  };

  return (
    <APIProvider apiKey={googleMapsApiKey}>
      <div className="flex flex-col md:flex-row">
        <div className={`${fullScreen ? "hidden" : "w-[100%] md:w-[65%]"}`}>
          <UpdatedHero />
        </div>
        <div
          className={` ${
            fullScreen
              ? "w-full h-[94vh]"
              : "w-[80%] md:w-[35%] mx-[10%] md:m-10 h-[350px] md:h-[80vh]"
          }`}
        >
          <Map
            defaultZoom={12}
            defaultCenter={position}
            mapId={googleMapsId}
            gestureHandling={"greedy"} //turns off "Ctrl + scroll to zoom" feature
            onZoomChanged={handleZoomChange}
            onClick={makeFullScreen}
          >
            <div className={`${fullScreen ? "block" : "hidden"}`}>
              <button
                className="absolute top-[60px] left-[15px] bg-[#00df9a] rounded-full p-1 px-2 border-black border-2"
                onClick={minimizeFullScreen}
              >
                Exit Full Screen
              </button>
            </div>

            {/* These are the real locations */}
            <DetailedPin
              position={{ lat: 44.02923, lng: -92.487165 }}
              title="Hwy 14 Downtown"
              price="350"
              type="Billboard"
              zoom={zoom}
            />
            <DetailedPin
              position={{ lat: 43.965012, lng: -92.462693 }}
              title="Broadway Ave S #1"
              price="500"
              type="Billboard"
              zoom={zoom}
            />
            <DetailedPin
              position={{ lat: 43.966573, lng: -92.462291 }}
              title="Broadway Ave S #2"
              price="500"
              type="Billboard"
              zoom={zoom}
            />
            <DetailedPin
              position={{ lat: 43.968235, lng: -92.462361 }}
              title="Broadway Ave S #3"
              price="500"
              type="Billboard"
              zoom={zoom}
            />
            {/* These are the dummy locations */}
            <DetailedPin
              position={{ lat: 44.0409, lng: -92.45211 }}
              title="Hwy 14 Downtown"
              price="350"
              type="Billboard"
              zoom={zoom}
            />
            <DetailedPin
              position={{ lat: 44.041244, lng: -92.5375 }}
              title="Broadway Ave S #1"
              price="500"
              type="Billboard"
              zoom={zoom}
            />
            <DetailedPin
              position={{ lat: 43.98763, lng: -92.425897 }}
              title="Broadway Ave S #2"
              price="500"
              type="Billboard"
              zoom={zoom}
            />
            <DetailedPin
              position={{ lat: 43.98162, lng: -92.507307 }}
              title="Broadway Ave S #3"
              price="500"
              type="Billboard"
              zoom={zoom}
            />
            <DetailedPin
              position={{ lat: 44.016906, lng: -92.47954 }}
              title="Broadway Ave S #3"
              price="500"
              type="Billboard"
              zoom={zoom}
            />
          </Map>
          <h1
            onClick={makeFullScreen}
            className="bg-white w-[240px] rounded-b-lg px-2 cursor-pointer"
          >
            Click on the map for full screen
          </h1>
        </div>
      </div>
    </APIProvider>
  );
}
