import React from "react";

const SocialProof = () => {
  return (
    <div className="bg-[#252525] h-[125px] md:h-[200px] w-[100%] mt-10 md:mt-0">
      <h1 className="text-[#868686] pl-5 pt-3">
        Trusted by the Worlds Leading Advertisers
      </h1>
      <div className="flex flex-row justify-around *:h-[75px] *:md:h-[125px] *:md:pt-5">
        <img alt="company logo" src="143logo.png" />
        <img alt="company logo" src="143logo.png" />
        <img alt="company logo" src="143logo.png" />
      </div>
    </div>
  );
};

export default SocialProof;
