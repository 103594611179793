import React from "react";
import Map from "../components/Map";
import Mission from "../components/Mission";
import SocialProof from "../components/SocialProof";
import ImageTextBlock from "../components/ImageTextBlock";

const MapPage = () => {
  return (
    <div>
      <Map />
      <SocialProof />
      <ImageTextBlock
        title="Title Test"
        text="This will be the paragraph"
        src="Roadwbillboards.png"
        alt="Road W/ Billboards"
      />
      <ImageTextBlock
        title="Title Test"
        text="This will be the paragraph"
        src="Roadwbillboards.png"
        alt="Road W/ Billboards"
        reverse={true}
      />
      <ImageTextBlock
        title="Title Test"
        text="This will be the paragraph"
        src="Roadwbillboards.png"
        alt="Road W/ Billboards"
      />
      <Mission />
    </div>
  );
};

export default MapPage;
