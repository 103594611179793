import React from "react";

const ImageTextBlock = ({ title, text, src, alt, reverse }) => {
  return (
    <div
      className={`flex flex-col ${
        reverse ? "md:flex-row-reverse" : "md:flex-row"
      } justify-center`}
    >
      <div className="w-[80%] m-[10%] md:w-[35%] md:m-[2.5%]">
        <h1 className="w-[60%] text-[#00df9a] text-[35px]">{title}</h1>
        <p1 className="text-[20px] text-white">{text}</p1>
      </div>
      <img
        className="w-[80%] m-[10%] md:w-[35%] md:m-[2.5%]"
        alt={alt}
        src={src}
      />
    </div>
  );
};

export default ImageTextBlock;
