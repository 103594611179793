import React, { useState } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { FaMapMarker } from "react-icons/fa";
import Icon from "@mdi/react";
import { mdiBillboard } from "@mdi/js";

const DetailedPin = ({ position, type, onClick, title, price, zoom }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const isZoomedOut = zoom < 11;

  return (
    <AdvancedMarker position={position} onClick={handleClick} type={type}>
      {isZoomedOut ? (
        <div className="rounded-full h-3 w-3 bg-[#00df9a] border-2 border-black"></div>
      ) : (
        <div
          className={`drop-shadow-[0_10px_10px_rgba(0,0,0,0.35)] hover:drop-shadow-[0_15px_10px_rgba(0,0,0,0.35)] hover:scale-125 ease-in-out duration-200 ${
            isOpen ? "hidden" : "block"
          }`}
        >
          <FaMapMarker className="h-11 w-11 fill-[#323232]" />
          <Icon
            className="absolute top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00df9a]"
            path={mdiBillboard}
            size={1}
          />
        </div>
      )}
      <div
        className={`absolute top-[-75px] left-[-112px] bg-[#323232] border-2 border-black rounded-lg p-4 ${
          isOpen
            ? "block drop-shadow-[0_10px_10px_rgba(0,0,0,0.35)] hover:drop-shadow-[0_15px_10px_rgba(0,0,0,0.35)] hover:scale-x-[1.01] hover:scale-y-[1.01]"
            : "scale-0 bg-transparent text-transparent top-[-15px]"
        } ease-in-out duration-200`}
      >
        <div className="flex flex-row w-56">
          <Icon path={mdiBillboard} size={3} className="text-gray-400" />
          <div className="flex flex-col pl-3">
            <h2 className="text-base text-ellipsis text-[#00df9a] font-bold mb-1">
              {title}
            </h2>
            <h1 className="text-sm font-normal text-gray-400">${price}</h1>
            <h1 className="pt-1 hover:underline font-normal text-gray-400">
              Contact Host
            </h1>
          </div>
        </div>
      </div>
    </AdvancedMarker>
  );
};

export default DetailedPin;
